<script>
export default {
  name: 'ProductBookingMixins',
  computed: {
    productId() {
      return this.$route.params.id;
    },
    storeModuleName() {
      return !!this.productId ? `product/${this.productId}` : 'product';
    },
  },
};
</script>
