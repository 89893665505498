<template>
  <v-container class="px-0 py-3">
    <v-row>
      <v-col justify-self="center">
        <template v-if="hasOrderOption && hasSelectedPasses">
          <ProductQuestionsContainer
            ref="travelerQuestion"
            :is-loading="loading"
            @validate="$emit('validate')"
            @is-valid="$emit('is-valid', $event)"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductQuestionsContainer from '../features/QuestionsForm/QuestionsContainer';
import ProductOrderInformationMixins from './mixins/ProductOrderInformationMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ProductOrderMixins from '@/modules/product/mixins/ProductOrderMixins';
import ProductQuestionsMixins from '../mixins/QuestionsMixins';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';

export default {
  name: 'BookingFormPage',
  mixins: [
    ProductOrderInformationMixins,
    GoogleTagManagerMixins,
    ProductOrderMixins,
    ProductQuestionsMixins,
    TravelerMixins,
  ],

  components: {
    ProductQuestionsContainer,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.hasCreatedOrder) {
      this.clearOrderCreationState();
    }
  },

  methods: {
    validate() {
      this.$refs.travelerQuestion.validate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
