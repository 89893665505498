<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col cols="12">
        <template v-if="loading">
          <v-skeleton-loader height="94" type="list-item-three-line" />
        </template>

        <template v-else>
          <template v-if="hasError">
            <p class="pa-5 mb-0">
              {{ productQuestions.error }}
            </p>
          </template>

          <template v-else>
            <v-form
              data-cy="contact-info-form"
              ref="form"
              role="ProductQuestionsForm"
              aria-label="Contact information"
            >
              <ProductQuestionsForm
                v-for="(item, index) in productQuestions.value"
                :key="index"
                :index="index"
                :questions="item.questions"
                :title="item.title"
                @is-valid="setEnableButton($event)"
                @createTraveler="onCreateTraveler"
                @formChange="onFormChange"
              />
            </v-form>
            <v-row class="ma-0">
              <template v-if="hasOrderOption && $vuetify.breakpoint.mdAndDown">
                <v-btn
                  class="primary size16-weight700"
                  justify="end"
                  depressed
                  block
                  :height="60"
                  data-cy="go-to-payment"
                  :loading="isLoading"
                  :disabled="!buttonEnabled"
                  @click="$emit('validate')"
                >
                  {{ $t('proceed_to_payment') }}
                </v-btn>
              </template>
            </v-row>
          </template>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductQuestionsMixins from '@/modules/product/mixins/QuestionsMixins';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import ProductOrderMixins from '@/modules/product/mixins/ProductOrderMixins.vue';
import ProductQuestionsForm from './QuestionsForm';

export default {
  name: 'QuestionsContainer',
  mixins: [
    ProductQuestionsMixins,
    ProductOrderInformationMixins,
    ProductOrderMixins,
  ],

  components: { ProductQuestionsForm },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      travelers: [],
      buttonEnabled: false,
    };
  },

  created() {
    if (this.orderInformation.passesReady) {
      var passes = [];
      this.orderInformation.selectedPasses.forEach((pass) => {
        for (var i = 0; i < pass.value; i++) {
          passes.push(pass.id);
        }
      });
      this.loadQuestions({ passIds: passes });
    }
  },

  computed: {
    answers() {
      return { ...this.orderInformation.answers };
    },

    loading() {
      return this.productQuestions.isLoading;
    },

    hasModel() {
      return (
        this.productQuestions.value !== null &&
        this.productQuestions.value !== undefined
      );
    },

    hasError() {
      return (
        this.productQuestions.error !== null &&
        this.productQuestions.error !== undefined &&
        !this.hasModel
      );
    },
  },

  methods: {
    onFormChange(val) {
      this.answers[val.id] = val.value;
      this.answerQuestions(this.answers);
    },

    validate() {
      if (this.$refs.form.validate()) {
        this.setTravelers(this.travelers);
        this.$emit('submitForm');
      }
    },

    goToDetails() {
      this.$router.push({
        name: 'booking-details',
      });
    },

    onCreateTraveler(traveler) {
      let newTraveler = true;

      if (this.travelers.length) {
        this.travelers.map((item) => {
          if (item.id === traveler.id) {
            newTraveler = false;
            return traveler;
          }
        });
      }

      if (newTraveler) this.travelers.push(traveler);
    },

    setEnableButton(enable) {
      this.buttonEnabled = enable;
      this.$emit('is-valid', this.buttonEnabled);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.traveler-return-link {
  color: var(--v-grey9-base);
  text-decoration: none;
}
</style>
